import styled from 'styled-components';
import { Form } from 'formik';

import { loginFormStyles, loginTitleStyles } from 'components/LoginWrapper/styles';
import colors from 'styles/colors';

export const Content = styled(Form)`
  ${loginFormStyles};
`;

export const Title = styled.h1`
  ${loginTitleStyles};
`;

export const LoginFormActionsStyled = styled.div`
  width: 100%;
  text-align: center;
`;

export const AccountBlockedMessageStyled = styled.div`
  margin-inline: auto;
  padding: 10px;
  border-radius: 5px;
  width: 100%;    
  max-width: 364px;
  background: ${colors.error};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  white-space: pre-line;
`;
