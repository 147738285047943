import React, { useEffect } from 'react';

import usePrefix from 'utils/usePrefix';
import Icon from 'components/Icons';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { NotificationsListTypes } from 'App/reducer';
import { notificationTypes } from 'utils/constants';
import colors from 'styles/colors';

import { ButtonClose, Container, Message, IconBox, IconStyled } from './styles';

const Notification: React.FC<NotificationsListTypes> = ({ id, notification }) => {
  const [, dispatch] = useApp();
  const NOTIFICATIONS_PREFIX = 'Notifications';
  const t = usePrefix(NOTIFICATIONS_PREFIX);

  const clearCode = () => dispatch({ type: ActionTypes.REMOVE_NOTIFICATION, payload: id });

  const isError = notification.type === notificationTypes.error;

  useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    const timeout = setTimeout(() => clearCode(), 3000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container justifyContent="flex-start" alignItems="flex-start">
      <ButtonClose onClick={clearCode} title={t('close_notification')}>
        <Icon type={'close'} width="24" height="24" />
      </ButtonClose>
      <IconBox justifyContent="flex-start" alignItems="flex-start">
        <IconStyled
          type={isError ? 'errorCircle' : 'successCircle'}
          color={isError ? colors.error : colors.success}
          width="24"
          height="24"
        />
      </IconBox>
      <Message>
        <p>
          {notification.code
            ? t(notification.code) === `${NOTIFICATIONS_PREFIX}.${notification.code}`
              ? t('GENERAL_ERROR')
              : t(notification.code)
            : isError
            ? t('GENERAL_ERROR')
            : t('GENERAL_SUCCESS')}
        </p>
      </Message>
    </Container>
  );
};

export default Notification;
